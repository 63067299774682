<script setup>
 const props = defineProps({
  card: Object,
  index: Number,
  scrollTo: Number,
  leftOffset: Number,
  activeIndex: Number,
  activeDrift: Number,
  button: Boolean,
  buttonLabel: String,
  tickets: [Boolean, Array],
 });

 const { card, index, scrollTo, leftOffset, activeIndex, activeDrift, button, buttonLabel, tickets } = toRefs(props);

 //  console.log(card.value);

 // Variables
 const id = card?.id || card.value?.id;
 const image = card.value?.gallery?.[0] || card.value?.data?.gallery?.[0];
 const title = card.value?.title || card.value?.data?.title;
 const description = card.value?.description || card.value?.data?.description;
 const type = card.value?.filters?.tourType || card.value?.data?.filters?.tourType;
 const popular = card.value?.popular || card.value?.data?.popular;
 const performances = card.value?.performances || card.value?.data?.performances;
 const link = card.value?.url || card.value?.data?.url;
 const item = ref(null);

 // Computed
 const indexDelta = computed(() => {
  return index.value - activeIndex.value;
 });
 const tag = computed(() => {
  let tag = '';

  if (type) tag = type;
  if (type && popular) tag += ' • ';
  if (popular) tag += '<span>our most popular tour!</span>';

  return tag;
 });

 const featured = computed(() => {
  const pixels = scrollTo.value / index.value;
  const width = item.value?.clientWidth;

  if (index.value == 0) return scrollTo.value === 0;
  return width - pixels <= 5 && width - pixels >= -5;
 });

 const truncatedText = computed(() => {
  if (!description) return '';

  // Set lengths
  let maxLength = 100;

  // Trim description to max length
  let trimmed = description.trim();
  trimmed = trimmed.slice(0, maxLength);

  // Remove last word
  let wordArray = trimmed.split(' ');
  wordArray.splice(-1, 1);

  // Join words
  trimmed = wordArray.join(' ');

  const removeLastCharArray = ['.', ',', '!', '?', ':', ';'];
  for (const char of removeLastCharArray) {
   if (trimmed.endsWith(char)) trimmed = trimmed.slice(0, -1);
  }
  // if (trimmed.endsWith(',')) trimmed = trimmed.slice(0, -1);
  // if (trimmed.endsWith('.')) trimmed = trimmed.slice(0, -1);
  // if (trimmed.endsWith('!')) trimmed = trimmed.slice(0, -1);
  // if (trimmed.endsWith('?')) trimmed = trimmed.slice(0, -1);
  // if (trimmed.endsWith(':')) trimmed = trimmed.slice(0, -1);
  // if (trimmed.endsWith(';')) trimmed = trimmed.slice(0, -1);

  // Return trimmed description
  if (description.length > maxLength) return trimmed + '...';
  else return description;
 });

 const departures = computed(() => {
  if (button.value) return false;

  return performances.map((performance) => {
   const ticketCount = tickets.value.find((ticket) => ticket.PerformanceId == performance.id)?.AvailableCount || 'n/a';
   const time = new Date(performance.performanceDateTime).toLocaleTimeString('en-US', {
    timeStyle: 'short',
    timeZone: 'America/Chicago',
   });

   return {
    id: performance.id,
    expired: new Date(performance.performanceDateTime) < new Date() || (ticketCount < 1 && ticketCount !== 'n/a'),
    time,
    ticketCount,
    link: link + '?date=' + performance.performanceDateTime,
   };
  });
 });
</script>

<template>
 <div
  class="city-tours-draggable-carousel-card"
  :key="`city-tour-card-${id}`"
  :class="`${featured ? 'featured' : ''}`"
  :data-delta="indexDelta"
  :style="`--delta: ${indexDelta < 1 ? indexDelta * -1 : (indexDelta - 1) * -1};`"
  ref="item"
 >
  <div class="media">
   <MessLink v-if="link" :href="link">
    <MessImage v-if="image" :image="image" />
    <ProjectPill v-if="tag" :text="tag" class="image-tag"><span v-html="tag" /> </ProjectPill>
   </MessLink>
  </div>
  <div class="content">
   <MessLink v-if="link" :href="link">
    <MessHtml v-if="title" :html="title" tag="h3" class="title h4" />
    <MessHtml v-if="truncatedText" :html="truncatedText" class="copy" />
   </MessLink>

   <template v-if="button">
    <div>
     <ProjectButton
      class="button-tickets-times"
      :label="buttonLabel || 'Tickets & Times'"
      :title="`Learn more about the ${title} tour!`"
      :link="link"
     />
    </div>
   </template>
   <template v-else>
    <div>
     <h4 class="h6">Selected Days's Departures</h4>
     <ul class="departures">
      <li v-for="departure in departures" :key="departure.id + departure.time">
       <ProjectButton
        v-if="departure"
        :label="departure.time"
        :link="departure.link"
        :title="departure.expired ? 'This performance has expired' : 'Learn more about the' + title + ' tour!'"
        class="red"
        :class="`${departure.expired ? 'expired' : 'active'}`"
       />
      </li>
     </ul>
    </div>
   </template>
  </div>
 </div>
</template>

<style lang="scss">
 .city-tours-draggable-carousel-card {
  --shift-width: calc(15% + 10px);
  --shift: calc(var(--delta) * var(--shift-width));
  --transition: 350ms ease-in-out;

  user-select: none;
  padding-right: 20px;

  transform: scale(0.85);
  transform-origin: top left;

  transition: var(--transition);
  transform: scale(0.85) translateX(var(--shift));
  z-index: var(--delta);

  a {
   pointer-events: none;
  }

  &.featured {
   .media {
    .mess-image {
     filter: grayscale(0%);
    }
   }
   a {
    // user-select: auto;
    // pointer-events: auto;
    .mess-image {
     pointer-events: none;
    }
   }

   &:hover {
    .mess-image {
     filter: grayscale(100%);
    }
    .content {
     background: white;
     transform: translateY(-3rem);
    }
   }
  }

  &[data-delta='0'] {
   transform: scale(1);
  }

  .media {
   position: relative;

   .pill {
    --offset: 1rem;
    position: absolute;
    max-width: calc(100% - (var(--offset) * 2));
    top: var(--offset);
    left: var(--offset);

    span {
     color: var(--red, #c00);
     text-align: center;
     font-size: 12px;
     font-style: normal;
     font-weight: 900;
     line-height: 123.1%; /* 14.772px */
     letter-spacing: 1.38px;
     text-transform: uppercase;
    }
   }
   .mess-image {
    aspect-ratio: 11/8;
    filter: grayscale(40%);
    transition: var(--transition);
   }
  }

  .content {
   padding-top: 1.5rem;
   background: white;
   transition: var(--transition);
   h4 {
    margin-top: 1.5rem;
   }
   .button-tickets-times {
    margin-top: 1.5rem;
   }
   .departures {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 1rem;

    .expired {
     border-color: var(--gray-300);
     background: var(--gray-300);
     color: var(--gray-700);
    }
   }
  }
 }
</style>
